<template>
    <div>
        <title-bar :title="$t('navigations.categories')"/>
        <div v-if="rows" class="custom-table">
            <table-actions
                :actions="['addBtn', 'perPage']"
                :addBtnText="$t('messages.invoiceCategoryAdd')"
                :addBtnLink="$helper.getAddUrl('invoices/categories')"
                @perPageChange="perPageChange"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                @is-finished="isLoading = false"
            >
                <template v-slot:name="data">
                    {{ data.value.name }}
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn
                            :path="$helper.getEditUrl('invoices/categories', data.value.id)"
                        />
                        <delete-btn @pressDelete="deleteCategory(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";

export default {
    name: "InvoiceCategories",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        TitleBar,
        EditBtn,
        DeleteBtn
    },
    mixins: [ResourceUtils],
    data() {
        return {
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            columns: [
                {
                    label: this.$t("forms.name"),
                    field: "name",
                    sortable: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            this.$InvoiceCategories.getCollection({params}).then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        deleteCategory(url) {
            this.deleteByUrl(
                this.$InvoiceCategories,
                url,
                this.$t("messages.invoiceCategoryDeleted"),
                null,
                this.load,
                this.error
            );
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>
